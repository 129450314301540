import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {getToken} from "../../userAuth";
import {getNormsByShortTitle, sendNorms} from "../../api/apiAdmin";

export default function Norms() {
    const {short_title} = useParams();
    const [normType, setNormType] = useState('polynomial');
    const [norms, setNorms] = useState([]);
    const [dims, setDims] = useState([]);

    useEffect(() => {
        async function fetchNorms() {
            // Redirect user to their previous response, if test already taken.
            const data = {
                short_title: short_title,
                token: getToken(),
            };
            try {
                const res1 = await getNormsByShortTitle(data);
                setDims(res1.data.dims);
                setNorms(res1.data.norms.norms);
            } catch (error) {
                console.log(error);
            }
        }

        void fetchNorms();
    }, []);


    const handleNormTypeChange = (event) => {
        setNormType(event.target.value);
    };

    const addNorm = () => {
        const newNorm = {type: normType, details: {}};
        if (normType === 'scale') {
            newNorm.details = {name: '', mean: '', standardDeviation: ''};
        } else if (normType === 'polynomial') {
            newNorm.details = {name: '', questionText: '', meanCoefficients: [], sdCoefficients: []};
        }
        setNorms([...norms, newNorm]);
    };

    const removeNorm = (index) => {
        const newNorms = norms.filter((_, i) => i !== index);
        setNorms(newNorms);
    };

    const handleDetailChange = (normIndex, detailType, degreeIndex, value) => {
        setNorms(prevNorms => prevNorms.map((norm, index) => {
            if (index === normIndex) {
                // Check if the detail type is an array (like coefficients)
                if (Array.isArray(norm.details[detailType])) {
                    // Update the specific coefficient at the degree index
                    let updatedCoefficients = [...norm.details[detailType]];
                    updatedCoefficients[degreeIndex] = value;

                    return {
                        ...norm,
                        details: {
                            ...norm.details,
                            [detailType]: updatedCoefficients
                        }
                    };
                } else {
                    // For non-array details (like name, questionText)
                    return {
                        ...norm,
                        details: {
                            ...norm.details,
                            [detailType]: value
                        }
                    };
                }
            }
            return norm;
        }));
    };


    const addCoefficient = (normIndex, type) => {
        const newNorms = [...norms];
        newNorms[normIndex].details[type].push('');
        setNorms(newNorms);
    };

    const removeLastCoefficient = (normIndex, type) => {
        const newNorms = [...norms];
        if (newNorms[normIndex].details[type].length > 0) {
            newNorms[normIndex].details[type].pop();
        }
        setNorms(newNorms);
    };

    const renderPolynomialInputs = (norm, index) => (
        <Card className="mt-3" key={index}>
            <Card.Body>
                {/* Type Selector */}
                <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                        value={norm.details.type}
                        onChange={(e) => handleDetailChange(index, 'type', 0, e.target.value)}
                    >
                        <option value="Custom">Custom</option>
                        <option value="Gender">Gender</option>
                        <option value="Age">Age</option>
                        <option value="Ethnicity">Ethnicity</option>
                    </Form.Select>
                </Form.Group>
    
                {/* Dimension Selector */}
                <Form.Group className="mb-3">
                    <Form.Label>Dimension</Form.Label>
                    <Form.Select
                        value={norm.details.dimension || ''}
                        onChange={(e) => handleDetailChange(index, 'dimension', 0, e.target.value)}
                    >
                        <option value="">Select Dimension</option>
                        {dims && dims.map((dim, dimIndex) => (
                            <option key={dimIndex} value={dim}>{dim}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

    
                {/* Conditionally render Name and Question Text for Custom type */}
                {norm.details.type === 'Custom' && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={norm.details.name}
                                onChange={(e) => handleDetailChange(index, 'name', 0, e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Question Text</Form.Label>
                            <Form.Control
                                type="text"
                                name="questionText"
                                value={norm.details.questionText}
                                onChange={(e) => handleDetailChange(index, 'questionText', 0, e.target.value)}
                            />
                        </Form.Group>
                    </>
                )}
    
                {/* Coefficients Sections */}
                {['mean', 'sd'].map((type) => (
                    <div key={type}>
                        <h5>{type.toUpperCase()} Coefficients</h5>
                        {norm.details[type + 'Coefficients'] && norm.details[type + 'Coefficients'].map((coefficient, degreeIndex) => (
                            <Row className="mb-2" key={degreeIndex}>
                                <Col>
                                    <Form.Label>Degree {degreeIndex}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={coefficient}
                                        onChange={(e) => handleDetailChange(index, `${type}Coefficients`, degreeIndex, e.target.value)}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <div className="d-flex justify-content-start mb-2">
                            <Button variant="secondary" size="sm"
                                    onClick={() => addCoefficient(index, `${type}Coefficients`)}>Add
                                Coefficient</Button>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                style={{marginLeft: '10px'}}
                                onClick={() => removeLastCoefficient(index, `${type}Coefficients`)}>
                                Remove Last Coefficient
                            </Button>
                        </div>
                    </div>
                ))}
                <Button variant="danger" className="mt-3" onClick={() => removeNorm(index)}>Remove</Button>
            </Card.Body>
        </Card>
    );
    

    const renderScaleInputs = (norm, index) => (
        <Card className="mt-3" key={index}>
            <Card.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={norm.details.name}
                        onChange={(e) => handleDetailChange(index, 'name', 0, e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mean</Form.Label>
                    <Form.Control
                        type="number"
                        name="mean"
                        value={norm.details.mean}
                        onChange={(e) => handleDetailChange(index, 'mean', 0, e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Standard Deviation</Form.Label>
                    <Form.Control
                        type="number"
                        name="standardDeviation"
                        value={norm.details.standardDeviation}
                        onChange={(e) => handleDetailChange(index, 'standardDeviation', 0, e.target.value)}
                    />
                </Form.Group>
                {/* Dimension Selector */}
                <Form.Group className="mb-3">
                    <Form.Label>Dimension</Form.Label>
                    <Form.Select
                        value={norm.details.dimension || ''}
                        onChange={(e) => handleDetailChange(index, 'dimension', 0, e.target.value)}
                    >
                        <option value="">Select Dimension</option>
                        {dims && dims.map((dim, dimIndex) => (
                            <option key={dimIndex} value={dim}>{dim}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Button variant="danger" onClick={() => removeNorm(index)}>Remove</Button>
            </Card.Body>
        </Card>
    );

    const submitNorms = async () => {
        const data = {
            short_title: short_title,
            norms: norms,
            token: getToken(),
        };
        console.log(data);
        
        try {
            const re = await sendNorms(data);
            console.log(re);
            alert('Submission Successful!');
            window.location.reload();
        } catch (e) {
            console.log(e);
            alert('Submission Failed! Check account privileges.');
            window.location.reload();
        }
    };

    return (
        <Container className='mt-5'>
            <Card className="m-auto align-self-center" style={{width: '36rem'}}>
                <Card.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Norm Type</Form.Label>
                            <Form.Control as="select" value={normType} onChange={handleNormTypeChange}>
                                <option value="polynomial">Polynomial</option>
                                <option value="scale">Scale</option>
                            </Form.Control>
                        </Form.Group>
                        <div className="d-flex justify-content-center mb-3">
                            <Button variant="primary" onClick={addNorm}>Add Norm</Button>
                        </div>
                    </Form>
                    {norms.map((norm, index) =>
                        norm.type === 'polynomial' ? renderPolynomialInputs(norm, index) :
                            norm.type === 'scale' ? renderScaleInputs(norm, index) : null
                    )}
                </Card.Body>
            </Card>
            <div className="d-flex justify-content-center mt-3">
                <Button variant="success" onClick={() => {
                    console.log(norms);
                    submitNorms();
                }}>Submit Norms</Button>
            </div>
        </Container>
    );
}

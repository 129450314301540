import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getNormScores, getPollResponseByUserApi, removePollResponseByUserApi} from "../../api/apiUser";
import {Button, Card, Collapse, Row, ButtonGroup} from "react-bootstrap";
import {getPollByShortTitleApi, getMaxScoreApi} from "../../api/apiAnon";
import {getToken, handleAuthenticationError} from "../../userAuth";
import {BellCurve} from "./BellCurve";
import {Resultsgram} from "./Resultsgram";
import DOMPurify from "dompurify";
import { jStat } from 'jstat';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function UserResponse() {
    const navigate = useNavigate();
    const {short_title} = useParams();
    const [poll, setPoll] = useState([]);
    const [pollResults, setpollResults] = useState([]);
    const [pollScores, setpollScores] = useState([]);
    const [normedScore, setnormedScore] = useState([]);
    const [open, setOpen] = useState(false);
    const [dimensions, setDimensions] = useState(['Score']);
    const [rawScore, setrawScore] = useState([]);
    const [takeNorm, setTakeNorm] = useState(false);
    const [data, setData] = useState([]);
    const [mode, setMode] = useState('Histogram');
    const [normData, setNormData] = useState([]);
    const [pcts, setPcts] = useState([]);
    const [eps, seteps] = useState([]);
    const [truncVal, setTv] = useState(999);
    const [sample, setSample] = useState(0);
    const [residx, setResIdx] = useState(0);

    const handleModeChange = (newMode) => {
        setMode(newMode);
    };

    const resetTest = async () => {
        try {
            /*
            await removePollResponseByUserApi({
                poll_id: short_title,
                token: getToken(),
            }); */
            navigate(`/poll/${poll.short_title}`);
        } catch (error) {
            handleAuthenticationError(error);
            console.error('Error fetching results:', error);
        }
    }

    function getNthColumn(arrayOfArrays, n) {
        return arrayOfArrays.map(row => row[n]);
      }
    function countArr(res, sums) {
        let trueCount = new Array(res[0][2].length).fill(0);
        let iterations = 0; // To hold the count of iterations
        res.forEach(item => {
            iterations++; // Increment iterations for each item
            item[2].forEach((v, i) => {
                if (v == true)
                {
                    v = 1;
                }
                trueCount[i] += v;
            });
        });
        //trueCount = trueCount.slice(0, -1);
        //trueCount = `[${trueCount.join(', ')}]`;
        //let outOf = `[${sums.join(', ')}]`;
        return [trueCount, sums];
            
    }

    function countTrueValues(indRes, sums) {

        let trueCount = 0; // To hold the count of "true" values
        let iterations = 0; // To hold the count of iterations
        let res = indRes.results; //list of [qid, response_str, q_score] where q_score is how you scored
        if (Array.isArray(indRes.results[0][2]) ) { //if q_score is multi_dim
            return countArr(res, sums);
        }
        // Iterate over response2.data.results[2]
        res.forEach(item => {
          iterations++; // Increment iterations for each item
          if (item[2] === 1 || item[2] === true) {
            trueCount++; // Increment trueCount if the item is true
          }
        });
        // Return the counts for further use if needed
        return [ trueCount, iterations ];
      }

      function empPercentiles(info) {
        
        // Function to calculate the percentile rank
        function getPercentile(data, score) {
          if (data.length === 0) return 0;
          data.sort((a, b) => a - b);
          let count = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i] <= score) {
              count++;
            }
          }
          return (count / data.length) * 100;
        }
      
        // Function to handle both single and multidimensional arrays
        function calculatePercentiles(data, scores) {
          if (Array.isArray(scores) && scores.length > 1) {
            return scores.map( (score, i) => getPercentile(getNthColumn(data,i), score));
          } else {
            if (scores != null)
            {
            return scores.map(score => getPercentile(data, score));
            }
            return 0;
          }
        }


        // Calculate raw and norm percentiles
        const rawPercentiles = calculatePercentiles(info.Rawdata, info.rawScores);
        const normPercentiles = calculatePercentiles(info.Normdata, info.Normscores);
      
        return {
          nep: normPercentiles,
          rep: rawPercentiles
        };
      }
    function meanSD(data)
    {
        function getColumn(arr, columnIndex) {
            return arr.map(row => row[columnIndex]);
        }
        if (Array.isArray(data[0]))
        {
            let r = [];
            data[0].forEach( (_, idx) => {
                let d = getColumn(data, idx);
                let mean = 0;
                for (let i = 0; i < d.length; ++i)
                {
                    mean += d[i] / d.length;
                }
                let sd = 0
                for (let i = 0; i < d.length; ++i)
                {
                    sd += (d[i] - mean)*(d[i] - mean) / d.length;
                }
                if (sd < 1)
                {
                    sd = 1;
                }
                r.push( [mean, sd + .1] );
            });
            return r;

        }
        else {
            let mean = 0;
            for (let i = 0; i < data.length; ++i)
            {
                mean += data[i] / data.length;
            }
            let sd = 0
            for (let i = 0; i < data.length; ++i)
            {
                sd += (data[i] - mean)*(data[i] - mean) / data.length;
            }
            if (sd < 1)
            {
                sd = 1;
            }
            return [[mean, sd + .1]];
        }
    }
    
    function randomNormalSample(mean, stdDev, m) {
        function randomNormal(mean, stdDev) {
            let u = 0, v = 0;
            while (u === 0) u = Math.random();
            while (v === 0) v = Math.random();
            return mean + stdDev * Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
        }
    
        const sample = [];
        for (let i = 0; i < m; i++) {
            sample.push(randomNormal(mean, stdDev));
        }
    
        return sample;
    }

    function rotate(l)
    {
        let ret = [];
        l[0].forEach((x,idx) => {
            ret.push(l.map(y => y[idx]));
        });
        return ret;
    }

    function fakeData(n, data)
    {
        let meanS = meanSD(data);
        let r = [];
        meanS.forEach(ms => {
            r.push(randomNormalSample(ms[0], ms[1], n));
            //generate data with size n on N(ms[0], ms[1])
        })
        return rotate(r);
    }

    useEffect(() => {
        async function fetchData() {
            const data = {
                short_title: short_title,
                token: getToken(),
            };
            try {
                const response1 = await getPollByShortTitleApi(short_title);
                if (response1.data.truncate != 'none' && response1.data.truncate != null && response1.data.truncate != '')
                {
                    setTv(response1.data.truncate);
                }   

                const res4 = await getMaxScoreApi(short_title);
                setPoll(response1.data);
                const responses = await getPollResponseByUserApi(data);
                const response3 = await getNormScores(data);
                let myNormScores = response3.data.results //array of arrays of score dicts per dim
                let myScores = responses.data.res //array of dicts of {results, scores}
                                                  // results: answers per response
                                                  // scores: a matrix. row is mean,score,std. column is dimension
                let firstResp = responses.data.res[0];
                setSample(firstResp.data.length);
                setDimensions(firstResp.dimensions);

                let resdata = firstResp.data;
                if (getNthColumn(resdata, 0).length < 25)
                {
                    resdata = fakeData(25, resdata);
                }
                else if (!Array.isArray(resdata[0]))
                {
                    resdata = resdata.map(x => [x]);
                }
                setData(resdata);
                setNormData(firstResp.normdata);

                console.log('responses', responses, myNormScores);
                if (myNormScores !== 'Error' && myNormScores !== 'None') {
                    setnormedScore(myNormScores);
                }
                if (response3.data.results !== 'Error')
                {
                    setTakeNorm(true);
                }

                let percentilesover = [];
                let normscoresover = [];
                if (Array.isArray(myNormScores))
                {
                    myNormScores.forEach(scoreList => {
                        //scoreList is a list of dicts, one per dim
                        let percentilesunder = [];
                        let normscoresunder = [];
                        scoreList.forEach(score => {
                            let percentile = jStat.normal.cdf(score.score, score.mean, score.std) * 100;
                            percentilesunder.push(Math.trunc(percentile));
                            normscoresunder.push(score.score);
                        });
                        percentilesover.push(percentilesunder);
                        normscoresover.push(normscoresunder);
                    });
                }
                let rawpercentiles = [];
                let rawScores = [];
                myScores.forEach(take => {
                    let rawpercentiles_ = [];
                    let rawScores_ = [];
                    if (!Array.isArray(take.scores.score))
                    {
                        take.scores.score = [take.scores.score];
                        take.scores.mean = [take.scores.mean];
                        take.scores.std = [take.scores.std];
                    }
                    take.scores.mean.forEach( (col, i) => {
                        
                        let percentile = jStat.normal.cdf(take.scores.score[i], take.scores.mean[i], take.scores.std[i]) * 100;
                        rawpercentiles_.push(Math.trunc(percentile));
                        rawScores_.push(take.scores.score[i]);
                    });
                    rawpercentiles.push(rawpercentiles_);
                    rawScores.push(rawScores_);
                })
                console.log(percentilesover, normscoresover, rawpercentiles, rawScores)
                let emps = [];
                rawpercentiles.forEach((row, i) =>{
                    let scoredata = {
                        'Normscores': normscoresover[i],
                        'rawScores': rawScores[i],
                        'Normdata': firstResp.normdata,
                        'Rawdata': resdata,
                    };
                    emps.push(empPercentiles(scoredata));
                })
                setResIdx(responses.data.res.length-1);
                seteps(emps);
                setPcts([percentilesover,rawpercentiles]);
                let pollres = myScores.map(r => r.results);
                setpollResults(pollres); 
                setpollScores(myScores.map(r => r.scores));
                let rawScore_ = myScores.map(r => countTrueValues(r, res4.data.sums));
                setrawScore(rawScore_);
                console.log(
                    emps,
                    [percentilesover,rawpercentiles],
                    pollres[0],
                    myScores.map(r => r.scores),
                    rawScore_,
                );
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }

        void fetchData();
    }, []);
    const resultSelect = (event) => {
        const num = parseInt(event.target.value, 10);
        setResIdx(num-1);
      };

    return (

        poll && pollResults[residx] && (
            

            <Stack sx={{display: "flex", alignItems: "center"}}>
                <h2>Your Response To:</h2>
                <h1>{poll.title}</h1>
                <ButtonGroup className="mb-3">
                <Button
                    variant={mode === 'Bell Curve' ? 'primary' : 'secondary'}
                    onClick={() => handleModeChange('Bell Curve')}
                >
                    Bell Curve
                </Button>
                <Button
                    variant={mode === 'Histogram' ? 'primary' : 'secondary'}
                    onClick={() => handleModeChange('Histogram')}
                >
                    Histogram
                </Button>
            </ButtonGroup>
            <h3 className="">{`Number of Responses from All: ${sample ||0}`}</h3>

            <FormControl variant="outlined" style={{ width: '200px' }}>
                    <InputLabel id="result-select-label">Select Result</InputLabel>
                    <Select
                        labelId="result-select-label"
                        id="result-select"
                        value={residx+1}
                        onChange={resultSelect}
                        label="Select Your Result"
                    >
                        {Array.from({ length: pollResults.length }, (_, idx) => (
                        <MenuItem key={idx+1} value={idx+1}>
                            {idx+1}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                {
                    normedScore[0] && normedScore[0].length > 1 ? (
                        <Row className="align-items-center justify-content-center center">
                        <h2 className="text-center w-100">Normed Scores</h2>
                        {normedScore[0].map((ns, index) => (
                            <Card className="m-1 p-0" key={index} style={{maxWidth: '19rem'}}>
                            <Card.Body>
                                    {mode === 'Bell Curve' ? (
                                        <BellCurve
                                        scores={{mean: ns.mean, std: ns.std, score: ns.score}}
                                        title={ns.dim} 
                                        data={data}
                                        truncate={truncVal}
                                        />
                                    ) : (
                                        <Resultsgram
                                        scores={{mean: ns.mean, std: ns.std, score: ns.score}}
                                        title={ns.dim} 
                                        data={normData}
                                        />
                                    )}
                                <Card.Footer>
                                    <Card.Subtitle>Score: {ns.score.toFixed(2)}</Card.Subtitle>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        ))}
                        </Row>

                    ) : (
                        takeNorm == true ?
                        (
                            normedScore[residx] && normedScore[residx].length > 0 ? (

                                normedScore[residx].map((ns, index) => (
                                    <>
                                       
                                        {mode === 'Bell Curve' ? (
                                        <div>
                                        <h2 className="text-center w-100">Normed Score: {ns.score}, {pcts[0][residx]}th percentile</h2>
                                        <BellCurve scores={ns} data={data} truncate={truncVal}/>
                                        </div>
                                    ) : (
                                        <div>
                                        <h2 className="text-center w-100">Normed Score: {ns.score}, {eps[residx].nep}th percentile</h2>
                                        <Resultsgram
                                        scores={ns} data={normData}
                                        />
                                        </div>
                                    )}
                                    </>
        

                                ))
                            ) : (

                                <p><a href={`/norms-test/${short_title}`}>Take Norm Test</a></p>
                          )
                        ) : (<p></p>)

                    )
                }
                
                
                {Array.isArray(pollScores[residx].mean) && pollScores[residx] ? (
                    <Row className="align-items-center justify-content-center center">
                        <h2 className="text-center w-100">{`Raw Scores`}</h2>
                        {pollScores[residx].mean.map((mean, i) => (
                            <Card className="m-1 p-0" key={i} style={{ maxWidth: '19rem' }}>
                                <Card.Body>
                                    {mode === 'Bell Curve' ? (
                                        <BellCurve
                                            scores={{ mean: mean, std: pollScores[residx].std[i], score: pollScores[residx].score[i] }}
                                            title={
                                                dimensions === null
                                                ? `${Math.trunc(eps[residx].rep[i])}th percentile` 
                                                : `${dimensions[i]} ${Math.trunc(eps[residx].rep[i])}th percentile`
                                            }
                                            data={getNthColumn(data,i)}
                                            truncate={truncVal}
                                        />
                                    ) : (
                                        <Resultsgram
                                            scores={{ mean: mean, std: pollScores[residx].std[i], score: pollScores[residx].score[i] }}
                                            title={
                                                dimensions === null
                                                ? `${Math.trunc(eps[residx].rep[i])}th percentile` 
                                                : `${dimensions[i]} ${Math.trunc(eps[residx].rep[i])}th percentile`
                                            }
                                            data={getNthColumn(data, i)}
                                            />
                                    )}
                                    <Card.Footer>
                                        <Card.Subtitle>Score: {pollScores[residx].score[i].toFixed(2)}</Card.Subtitle>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        ))}
                    </Row>
                ) : (
                    <>
                        {mode === 'Bell Curve' ? (
                            <>
                            <h2>Score: {pollScores[residx].score}, {pcts[1][residx]}th percentile</h2>
                            <BellCurve scores={pollScores[residx]} data={data} truncate={truncVal}/>
                            </>
                        ) : (
                            <>
                            <h2>Score: {pollScores[residx].score}, {Math.trunc(eps[residx].rep)}th percentile</h2>
                            <Resultsgram scores={pollScores[residx]} data={data} />
                            </>
                        )}
                    </>
                )}
                
                <br></br>
                <h3>Raw Score:</h3>
                <div>
                    {Array.isArray(rawScore[residx][0]) ? (
                    rawScore[residx][0].map((score, index) => (
                        <h3 key={`score-0-${index}`}>{`${dimensions[index]} : ${score} / ${rawScore[residx][1][index]}`}</h3>
                    ))
                    ) : (
                    <h3>{`${rawScore[residx][0]} / ${rawScore[residx][1]}`}</h3>
                    )}
                </div>

                
                <br></br>
            
                <div style={{ marginBottom: '16px' }}>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        {open ? 'Hide All Answers' : 'Show All Answers'}
                    </Button>
                </div>
                <Collapse in={open}>
                <div id="example-collapse-text" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px', justifyContent: 'center'}}>
                        <Stack spacing={2} sx={{width: 4 / 4}}>
                            {poll.questions.map((question, index) => {
                                let answers = pollResults[residx].filter(a => a[0] === question.id).map(a => [a[1], a[2]]);
                                if (answers.length > 0 && answers[0] !== '') {
                                    const safeHTML = DOMPurify.sanitize(question.prompt);
                                    return (
                                        <Card key={index}>
                                            <Card.Body>
                                                <Card.Title>
                                                    <div dangerouslySetInnerHTML={{__html: safeHTML}}/>
                                                </Card.Title>
                                                {
                                                    
                                                    answers.map((answer, answerIndex) => {
                                                        // Checking if answer[1] is 1 to decide the emoji or number
                                                        let resultDisplay = '';
                                                        if (answer[1] === 1 || answer[1] === true )
                                                        {
                                                            resultDisplay = '<span style="color: green;">✔️</span>';
                                                        }
                                                        if (answer[1] === 0 || answer[1] === false)
                                                        {
                                                            resultDisplay = '<span style="color: red;">x</span>';
                                                        }
                                                        if (Array.isArray(answer[1]))
                                                        {
                                                            resultDisplay = '[';
                                                            for (let i = 0; i < answer[1].length-1; ++i)
                                                            {
                                                                resultDisplay += answer[1][i].toString() + ',';
                                                            }
                                                            resultDisplay += answer[1][answer[1].length-1].toString()
                                                            resultDisplay += ']';
                                                        }
                                                        const answerText = DOMPurify.sanitize(answer[0] + '    ' + resultDisplay);
                                                        return <div key={answerIndex}>
                                                            <span className="choice-content"
                                                                  dangerouslySetInnerHTML={{__html: answerText}}/>
                
                                                            </div>
                                                    })
                                                }
                                            </Card.Body>
                                        </Card>
                                    );
                                }
                            })}
                        </Stack>
                    </div>
                </Collapse>



                <Link to={`/poll/${short_title}`} onClick={(e) => {
                    e.preventDefault();
                    void resetTest();
                }}>Take Again</Link>
               

                <Link to="/">Back to Home</Link>
            </Stack>
        )    
    )
}

/*
pollScores[residx].mean.map((mean, i) => (
                            <>
                                <h2>Score: {pollScores[residx].score[i]}</h2>
                                <BellCurve
                                    scores={{mean: mean, std: pollScores[residx].std[i], score: pollScores[residx].score[i]}}
                                    title={dimensions[i]}
                                />
                            </>
                        )) 
*/
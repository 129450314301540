import Button from "@mui/material/Button";
import React, {useEffect, useRef, useState} from "react";
import {getToken, handleAuthenticationError, isLoggedIn} from "../../userAuth";
import Container from "react-bootstrap/Container";
import {Card} from "react-bootstrap";
import {cancelDNAFile, hasUploadedDNAFile, uploadDNAFile, isResolved} from "../../api/apiDNA";
import ResultsDNA from "./ResultsDNA";
import JobStatus from "./JobStatus";
import {Dashboard} from "@uppy/react";
import Uppy from '@uppy/core';
import Signin from "../common/Signin";
import AgreementPrompt from "../common/AgreementPrompt";
import {CircularProgress} from "@mui/material";

export default function Genomics() {
    const [hasUploaded, setHasUploaded] = useState(false);
    const [hasResults, setHasResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const agreementBox = useRef(null);

    // Function to update hasResults for JobStatus
    const updateHasResults = (value) => {
        setHasResults(value);
    };

    const updateHasUploaded = (value) => {
        setHasUploaded(value);
    };

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await hasUploadedDNAFile({token: getToken()});
            setHasUploaded(response.data['hasUploaded']);
            setHasResults(response.data['completed']);

          } catch (error) {
            handleAuthenticationError(error);
          }
        }
        void fetchData();
      }, []);

    const fileData = () => {
        switch (hasResults ? "results" : hasUploaded ? "processing" : "default") {
            case "results":
                return (
                    <>
                      <ResultsDNA />
                    </>
                  );
            case "processing":
                return (
                    <>
                        <Card.Title>Your DNA is still processing. Check back later!</Card.Title>
                    </>
                );
            default:
                return (
                    <Card.Text>
                        Find your <b>23andMe</b> or <b>Ancestry.com</b> DNA .zip/.gz file and press upload!
                    </Card.Text>
                );
        }
    };

    const uppy = new Uppy({
        restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: ['application/zip', 'application/gzip', 'application/x-zip-compressed', 'zip'],
            maxFileSize: 1024 * 1024 * 30,
        },
        onBeforeUpload(_) {
            if (!agreementBox.current.checked) {
                uppy.info('You must agree to the T&Cs and Privacy Policy', 'error');
                return false;
            } else return true;
        }

    }).on('complete', async (result) => {
        setLoading(true)
        try {
            const fileResult = result['successful'][0];
            const formData = new FormData();
            formData.append("token", getToken());
            formData.append("file", fileResult['data'], fileResult['name']);
            const response = await uploadDNAFile(formData);
            setHasUploaded(true);
            window.alert("Upload Successful!");
        } catch (error) {
            handleAuthenticationError(error);
            if (error.response.status === 500) {
                alert(`\nPipeline is currently down, undergoing maintenance.\nPlease try again later.`);
            }
            alert(`Upload failed (${error.response.status}).\n${error.response.data['message']}`);
            console.error('Error exporting data:', error);
        } finally {
            setLoading(false);
        }
    });

    const cancelFile = async () => {
        if (!window.confirm("Are you sure you want to cancel your uploaded DNA?")) return;
        
        try {
            const jobResponse = await isResolved({ token: getToken() });
            if (jobResponse.data.status) {
                alert("This job has already been resolved. The page will now refresh.");
                window.location.reload();
                return;
            }
    
            await cancelDNAFile({token: getToken()});
            alert("Your DNA processing has been successfully cancelled.");
            window.location.reload();

        } catch (error) {
            console.error("Error during cancellation:", error);
            handleAuthenticationError(error);
            alert("An error occurred while trying to cancel the process. Please try again.");
        }
    }

    return (
        <Container className="p-4 pb-3 bg-body-tertiary" style={{borderRadius: '10px'}}>
            <h2>DNA Analysis</h2>
            <p>Utilize our free DNA analysis tools, employing the latest advancements in genome-wide association study
                (GWAS) science.</p>
            <Card className="align-items-center justify-content-center center">
                <Card.Body>
                    {
                        isLoggedIn() ? <>
                            {fileData()}
                            {
                                !loading && !hasUploaded && <>
                                    <AgreementPrompt checkboxRef={agreementBox}/>
                                    <Dashboard uppy={uppy}/>
                                </>
                            }
                            {
                                loading && <CircularProgress/>
                            }
                        </> : <div className="mb-5">
                            <Signin redirect={false}/>
                        </div>
                    }
                </Card.Body>
            </Card>
            {isLoggedIn() && (
                <div className="mt-3">
                    <JobStatus 
                        hasUploaded={hasUploaded}
                        hasResults={hasResults}
                        updateHasUploaded={updateHasUploaded}
                        updateHasResults={updateHasResults}
                    />
                    {hasUploaded && !hasResults && (
                        <Button 
                            variant="contained" 
                            color="error" 
                            onClick={cancelFile} 
                            className="mt-3"
                        >
                            Cancel Process
                        </Button>
                    )}
                </div>
            )}
        </Container>
    );
}
import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import {getAllPollsApi} from "../api/apiAnon";
import {getToken, handleAuthenticationError} from "../userAuth";
import PollCard from "./common/PollCard";
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {checkInfo} from "../api/apiUser";

export default function Home() {
    const navigate = useNavigate();
    const [polls, setPolls] = useState([]);
    const CACHE_KEY = 'pollsData';

    useEffect(() => {
        async function fetchData() {
            try {
                const cachedData = localStorage.getItem(CACHE_KEY);
                if (cachedData) {
                    console.log('Using cached data');
                    setPolls(JSON.parse(cachedData));
                }

                const response = await getAllPollsApi('true');
                setPolls(response.data);
                localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }
        async function redirInfo() {
            try {
                const response = await checkInfo({token: getToken()});
                if (response.data.message == 'Info not found')
                {
                    navigate('/info');
                }
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
        void redirInfo();
    }, []);

    return (
        <Container className="mt-3">
            <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect" style={{borderRadius: '10px'}}
                 onClick={() => navigate('/genomics')}>
                <Col>
                    <h2 style={{marginBottom: 1, marginTop: 4}}>DNA Analysis</h2>
                    <p><b>Have you had your DNA tested with <em>23andMe</em> or <em>Ancestry.com</em>?</b><br/>Upload
                        your DNA and see your genes score for traits such as Intelligence, Autism and Schizophrenia. 🔬
                    </p>
                </Col>
                <Col className="py-4 align-items-center justify-content-center center" md="auto">
                    <Link style={{color: 'black', textDecoration: 'none'}} to="/"><h1>❯</h1></Link>
                </Col>

            </Row>
            <Row className="p-4 pb-3 bg-body-tertiary" style={{borderRadius: '10px'}}>
                <h2>Psychology Tests </h2>
                <p>Participate in our personality assessments developed by psychometrics experts, drawing from
                    peer-reviewed scientific studies. 🧠</p>
                <hr/>

                <Row className="p-2 align-items-center justify-content-center center">
                    {polls.map((poll) => <PollCard poll={poll} key={poll.title}/>)}
                </Row>
            </Row>
        </Container>
    )
}

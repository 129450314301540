import React from 'react';
import {saveAs} from 'file-saver';
import {getCSV} from "../../api/apiAdmin";
import Button from '@mui/material/Button';
import {getToken} from "../../userAuth";

export default function ExportCSVButton({id}) {

    const handleExportToCSV = async () => {
        const data = {
            poll_id: id,
            token: getToken(),
        };
        try {
            const response = await getCSV(data);
            saveAs(new Blob([response.data]), 'poll_results.csv');
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={handleExportToCSV}>Export to CSV</Button>
    );
}